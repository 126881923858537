<template>
  <div class="mt-2 px-2 text-left pb-3">
    <div>
      <h3 class="font-weight-bold text-left">{{ $t('create_task.preview.title') }}</h3>
      <p>{{ $t('create_task.preview.description') }}</p>
      <div class="d-flex justify-content-center">
        <b-button v-on:click="showModal" class="btn-outline-primary bg-white button-width">{{ $t('create_task.preview.button') }}</b-button>
      </div>
    </div>
    <div class="mt-4 px-2 text-left border-gradient">
      <div class="p-3" v-if="$store.getters.hasRole('ROLE_SELLER')">
        <h3 class="font-weight-bold text-left">{{ $t('create_task.submit.title') }}</h3>
        <p>{{ $t('create_task.submit.description') }}</p>
        <div class="d-flex justify-content-center">
          <b-button v-on:click="updateStatus" class="btn-primary button-width position-relative" v-bind:class="{ disabled: !canBeSubmitted }">{{ $t('create_task.submit.button') }}</b-button>
        </div>
      </div>
      <div v-else class="p-3">
        <h3 class="font-weight-bold text-left">{{ $t('create_task.submit.title') }}</h3>
        <p>{{ $t('create_task.pre_seller_submit.description') }}</p>
        <div class="d-flex justify-content-center">
          <router-link :to="{ name: 'connecting-etn' }" class="btn btn-primary button-width position-relative">{{ $t('create_task.pre_seller_submit.button') }}</router-link>
        </div>
      </div>
    </div>
    <div class="text-right mt-5">
      <b-button v-on:click="switchSection" variant="link" class="text-dark mr-0 mr-md-3 mt-2 mt-md-0 button-width">{{ $t('create_task.back_button') }}</b-button>
    </div>
    <TaskPreview :taskData="task" :memberSince="user.member_since" :user="user" :languages="languages"/>
  </div>
</template>

<script>
import ApiService from '@/apiService'
import TaskPreview from '../Modal/TaskPreview'

export default {
  name: 'CreateTaskSection5',
  props: {
    task: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    languages: {
      type: Array,
      required: true
    }
  },
  components: {
    TaskPreview
  },
  methods: {
    switchSection () {
      this.$emit('switchSection', 3)
    },
    showModal () {
      this.$bvModal.show('task-preview')
    },
    updateStatus () {
      if (!this.canBeSubmitted) {
        return
      }

      this.$swal({
        title: this.$t('sure'),
        text: this.$t('activate_warning'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('activate_action'),
        cancelButtonText: this.$t('discard_action'),
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return ApiService.gigActivation(this.task.hash, { 'status': 2 }).then((response) => {
            return response.data.data[0]
          }).catch((error) => {
            this.$swal.showValidationMessage(
              '<p class="mb-0">' + error.response.data.error_message + '</p>'
            )
          })
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null && result.value.status === 2) {
          this.$router.push('/' + localStorage.getItem('username') + '/' + result.value.slug)
        }
      })
    }
  },
  computed: {
    canBeSubmitted () {
      if (this.task.packages[0].delivery === null || this.task.packages[0].price === null || this.task.packages[0].revisions === null) {
        return false
      }

      if (this.task.languages.length === 0 || this.task.categories.length === 0) {
        return false
      }

      if (this.task.slug === null || this.task.assets.length === 0) {
        return false
      }

      return ![1, 2].includes(this.task.status)
    }
  }
}
</script>

<style scoped lang="scss">
.border-gradient {
  position: relative;
}

.border-gradient::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  padding: 3px;
  background: linear-gradient(to bottom, #f07f13, #FF1F40);
  -webkit-mask: linear-gradient(#fff 0 0) content-box,
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

@include md-down {
  .button-width {
    width: 100%;
  }
}
</style>

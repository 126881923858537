<template>
  <div class="container pt-5 pb-5">
    <div>
      <div class="row">
        <div class="col-3 align-items-center d-none d-md-flex">
          <b-button class="hint-button" v-on:click="hintSwitchMethod"><i class="fa-regular fa-lightbulb" aria-hidden="true"/>
            {{ hintSwitch ? $t('create_task.tips_on') : $t('create_task.tips_off') }}
          </b-button>
        </div>
        <div class="col-12 col-md-9"><h1 class="font-weight-bold">{{ editTaskCheck ? $t('edit_task') : $t('task_create') }}</h1></div>
      </div>
      <div class="text-left back-button bold d-md-none" v-on:click="formShow = false" v-bind:class="{'d-none': !formShow}">
        <i class="fa-solid fa-arrow-left"></i> {{ $t('create_task.back') }}
      </div>
      <div class="line-holder mt-2 mb-3">
        <div class="line w-100"/>
        <div class="line glow-line"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 pb-3 d-md-block" id="navSection" v-bind:class="{'d-none': formShow}">
        <SidebarLine :active-state="activeSection" :complete-state="completedSections" :list="$t('create_task.navigation')" @sidebar-line-li-clicked="switchSection"/>
      </div>
      <div class="col-12 col-md-8 d-md-block" id="formSection" v-bind:class="{'d-none': !formShow}">
        <createTaskSection1 v-if="activeSection === 0" :task-description="task.description !== undefined ? task.description : ''"
                            :task-title="task.title !== undefined ? task.title : ''" :package-description="task.packages !== undefined ? task.packages[0].description : ''"
                            :hint-switch="hintSwitch" v-on:saveData="saveTask" :error-message="errorData"/>
        <createTaskSection2 v-else-if="activeSection === 1" :task-delivery="task.packages[0].delivery !== null ? task.packages[0].delivery : 24"
                            :task-price="task.packages[0].price !== null ? task.packages[0].price : 0" :error-message="errorData" v-on:saveData="saveTask"
                            :task-revisions="task.packages[0].revisions !== null ? task.packages[0].revisions : 1" :hint-switch="hintSwitch" v-on:switchSection="switchSection"/>
        <CreateTaskSection3 v-else-if="activeSection === 2" :hint-switch="hintSwitch" :taskCategory="categoryHashes" :error-message="errorData"
                            :taskLanguages="languageHashes" v-on:saveData="saveTask" v-on:switchSection="switchSection"/>
        <CreateTaskSection4 v-else-if="activeSection === 3" :task="task" :user="user" v-on:saveData="saveTask" v-on:switchSection="switchSection" :error-message="errorData"/>
        <CreateTaskSection5 v-else-if="activeSection === 4" :task="task" :user="user" :languages="languages" v-on:switchSection="switchSection"/>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarLine from '../../Global/Navigation/SidebarLine'
import CreateTaskSection1 from '../../Forms/CreateTaskSection1'
import CreateTaskSection2 from '../../Forms/CreateTaskSection2'
import CreateTaskSection3 from '../../Forms/CreateTaskSection3'
import CreateTaskSection5 from '../../Forms/CreateTaskSection5'
import CreateTaskSection4 from '../../Forms/CreateTaskSection4'
import apiService from '../../../apiService'
import config from '../../../config'

export default {
  name: 'CreateTaskLayout',
  components: {
    SidebarLine,
    CreateTaskSection1,
    CreateTaskSection2,
    CreateTaskSection3,
    CreateTaskSection4,
    CreateTaskSection5
  },
  data () {
    return {
      activeSection: 0,
      completedSections: 0,
      hintSwitch: true,
      task: this.taskProp,
      errorData: [],
      formShow: true,
      languageHashes: [],
      assetHashes: [],
      categoryHashes: [],
      newLanguageValues: [],
      languages: []
    }
  },
  props: {
    taskProp: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    editTaskCheck: {
      type: Boolean,
      required: false,
      default: false
    },
    user: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.getHashesFromTask(true)
    this.checkProgress(this.taskProp)
  },
  methods: {
    checkProgress (task) {
      if (!this.editTaskCheck) {
        this.completedSections = 0
      } else if (task.packages[0].delivery === null || task.packages[0].price === null || task.packages[0].revisions === null) {
        this.completedSections = 1
      } else if (task.languages.length === 0 || task.categories.length === 0) {
        this.completedSections = 2
      } else if (task.slug === null || task.assets.length === 0) {
        this.completedSections = 3
      } else {
        this.completedSections = 4
      }
    },
    getHashesFromTask (languages = false) {
      this.assetHashes = []
      this.categoryHashes = []

      if (this.task.categories !== undefined && this.task.categories.length > 0) {
        this.task.categories.forEach(value => {
          this.categoryHashes.push(value.hash)
        })
      }

      if (this.task.assets !== undefined && this.task.assets.length > 0) {
        this.task.assets.forEach(value => {
          this.assetHashes.push(value.hash)
        })
      }

      if (languages) {
        this.languageHashes = []
        if (this.task.languages !== undefined && this.task.languages.length > 0) {
          this.languages = this.task.languages
          this.task.languages.forEach(value => {
            this.languageHashes.push(value.code)
          })
        } else if (this.user.languages.length > 0) {
          this.languages = this.user.languages
          this.user.languages.forEach(value => {
            this.languageHashes.push(value.code)
          })
        }
      }
    },
    switchSection (data) {
      this.formShow = true
      this.activeSection = data
    },
    hintSwitchMethod () {
      this.hintSwitch = !this.hintSwitch
    },
    saveTask (data) {
      this.errorData = []
      if (this.activeSection === 0 && this.task.hash === undefined) {
        this.createTask(data)
      } else {
        if (!this.checkNoChanges(data)) {
          if (this.task.status === 1) {
            this.$swal({
              title: this.$t('sure'),
              text: this.$t('create_task.save_task.description'),
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: this.$t('create_task.save_task.button.confirm'),
              cancelButtonText: this.$t('discard_action'),
              showCloseButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.editTask(data)
              }
            })
          } else {
            this.editTask(data)
          }
        } else {
          this.activeSection = this.activeSection + 1
          window.scrollTo(0, 0)
        }
      }
    },
    checkNoChanges (data) {
      if (this.activeSection === 0) {
        return data.title === this.task.title && data.description === this.task.description && data.packages[0].description === this.task.packages[0].description
      } else if (this.activeSection === 1) {
        return data.price === this.task.packages[0].price && data.revisions === this.task.packages[0].revisions && data.delivery === this.task.packages[0].delivery
      } else if (this.activeSection === 2) {
        if (this.languageHashes.length === 0 && this.categoryHashes.length === 0) {
          return false
        }
        return JSON.stringify(data.categories) === JSON.stringify(this.categoryHashes) && JSON.stringify(data.languages) === JSON.stringify(this.languageHashes)
      } else if (this.activeSection === 3) {
        if (this.task.slug === null && this.assetHashes.length === 0) {
          return false
        }
        return JSON.stringify(data.assets) === JSON.stringify(this.assetHashes) && this.task.slug === data.slug
      }
    },
    updateTaskDefault (data) {
      let taskData = {
        title: this.task.title,
        description: this.task.description,
        packages: [{
          price: this.task.packages[0].price,
          revisions: this.task.packages[0].revisions,
          delivery: this.task.packages[0].delivery,
          description: this.task.packages[0].description
        }]
      }

      if (this.categoryHashes.length > 0) {
        taskData.categories = this.categoryHashes
      }

      if (this.assetHashes.length > 0) {
        taskData.assets = this.assetHashes
      }

      if (this.languageHashes.length > 0) {
        taskData.languages = this.languageHashes
      }

      if (this.activeSection === 0) {
        taskData.title = data.title
        taskData.description = data.description
        taskData.packages[0].description = data.packages[0].description
      } else if (this.activeSection === 1) {
        taskData.packages[0].price = data.price
        taskData.packages[0].revisions = data.revisions
        taskData.packages[0].delivery = data.delivery
      } else if (this.activeSection === 2) {
        taskData.languages = data.languages
        taskData.categories = data.categories
      } else if (this.activeSection === 3) {
        taskData.assets = data.assets
        taskData.slug = data.slug
      }
      return taskData
    },
    editTask (data) {
      apiService.editTaskHash(this.task.hash, this.updateTaskDefault(data)).then((resp) => {
        let response = resp.data.data[0]
        response.packages[0] = response['packages'][Object.keys(response.packages)[0]]
        this.task = response
        this.completedSections = this.activeSection === this.completedSections ? this.completedSections + 1 : this.completedSections
        this.activeSection = this.activeSection + 1
        this.getHashesFromTask(false)
        if (data.languages !== undefined) {
          this.languageHashes = data.languages
          this.languages = data.fullLanguages
        }
        window.scrollTo(0, 0)
      }).catch((error) => {
        this.errorHandling(error.response.data)
        return false
      })
    },
    createTask (data) {
      apiService.createTask(data).then((resp) => {
        if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            'event': 'taskCreate',
            'sellerID': this.user.hash,
            'taskID': resp.data.data.task.hash
          })
        }

        if (config.tracking_enabled === 'true') {
          window.__at_px.fire('task_created')
        }
        this.task = resp.data.data.task
        this.activeSection = 1
        this.completedSections = 1
        window.scrollTo(0, 0)
      }).catch((error) => {
        this.errorHandling(error.response.data)
        return false
      })
    },
    errorHandling (resp) {
      let errorData = []
      if (resp.code === 'HTTP-400') {
        for (let key in resp.errors.children) {
          if (key === 'packages') {
            let errorRoute = resp.errors.children.packages.children[0].children
            for (let packageKey in errorRoute) {
              if (errorRoute[packageKey].errors !== undefined) {
                errorData.push(errorRoute[packageKey].errors[0])
              }
            }
          } else if (resp.errors.children[key].errors !== undefined) {
            let errors = resp.errors.children[key].errors
            if (!Array.isArray(errors)) {
              errors = [errors]
            }
            errors.forEach((error) => {
              errorData.push(error)
            })
          }
        }
      } else {
        errorData.push(resp.error_message)
      }
      this.errorData = errorData
    }
  }
}
</script>

<style scoped lang="scss">
.line-holder {
  overflow: hidden;
  height: 20px;
}

.line {
  height: 2px;
  background: rgb(240, 127, 19);
  background: linear-gradient(90deg, $brand-tangerine 0%, $brand-scarlet 100%);
  border-radius: 4px;
}

.glow-line {
  filter: blur(4px);
  border-radius: 4px;
  height: 2px;
  width: 99%;
  margin: 0 auto;
  opacity: .9;
}

.hint-button, .hint-button:hover, .hint-button:active {
  font-size: 80%;
  font-weight: 400;
  color: #856404 !important;
  background-color: #fff3cd !important;
  border-color: #ffeeba !important;
}

.hint-button:focus {
  box-shadow: none !important;
}

.back-button {
  color: #6c757d;
  opacity: 0.5;
}
</style>

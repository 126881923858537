<template>
  <div class="bg-white">
    <CreateTaskLayout v-if="userLoaded && user.languages !== undefined" :user="user"/>
    <div v-else class="d-flex justify-content-center align-items-center flex-col loadingHeight">
      <b-spinner large label="Large Spinner"/>
      <div class="pt-2">{{ $t('loading') }}</div>
    </div>
  </div>
</template>

<script>
import CreateTaskLayout from '../../components/MyAccount/CreateTask/CreateTaskLayout'
import apiService from '../../apiService.js'
import { mapActions } from 'vuex'

export default {
  name: 'CreateTask',
  components: {
    CreateTaskLayout
  },
  data () {
    return {
      userLoaded: false,
      user: {}
    }
  },
  mounted () {
    this.checkUser()
    this.updateToken()
  },
  methods: {
    ...mapActions([
      'fetchNewAccessToken'
    ]),
    updateToken () {
      this.fetchNewAccessToken().then(() => {
        this.userLoaded = true
      })
    },
    checkUser () {
      apiService.getUser().then((response) => {
        this.user = response.data.data
      }).catch(() => {
      })
    }
  }

}
</script>

<style scoped>
.loadingHeight {
  min-height: inherit;
}
</style>

<template>
  <div class="bg-white">
    <CreateTaskLayout v-if="taskLoaded" :task-prop="tasks" :editTaskCheck="true" :user="tasks.user"/>
    <div v-else class="d-flex justify-content-center align-items-center flex-col loadingHeight">
      <b-spinner large label="Large Spinner"/>
      <div class="pt-2">{{ $t('loading') }}</div>
    </div>
  </div>
</template>

<script>
import CreateTaskLayout from '../../components/MyAccount/CreateTask/CreateTaskLayout'
import apiService from '../../apiService.js'
import { mapActions } from 'vuex'
export default {
  name: 'EditTask',
  components: {
    CreateTaskLayout
  },
  data () {
    return {
      tasks: [],
      languages: [],
      taskLoaded: false
    }
  },
  mounted () {
    this.getTask()
    this.updateToken()
  },
  methods: {
    ...mapActions([
      'fetchNewAccessToken'
    ]),
    updateToken () {
      this.fetchNewAccessToken()
    },
    getTask () {
      apiService.getTaskByHash(this.$route.params.slug).then((data) => {
        if (data.status === 400) {
          this.$router.push('/')
        }

        this.tasks = data.data['data'][0]

        this.taskLoaded = true
      }).catch((e) => {})
    }
  }
}
</script>
<style scoped>
.loadingHeight {
  min-height: inherit;
}
</style>

<template>
  <b-modal ref="task-preview" id="task-preview" size="xl" centered class="p-0" v-if="load" >
    <template #modal-header="{ close }">
      <h3 class="headerText">{{ $t('create_task.preview.modal_title') }}</h3>
      <span class="font-weight-bold headerButton" @click="close()">x</span>
    </template>
    <div class="row bg-gray mx-0 mobileDisplay">
      <div class="cols-12 col-lg-8">
        <div class="main-container pb-0 pt-3">
          <div class="container p-0">
            <!-- Block One -->
            <div class="card mb-0 mb-md-3 p-3">
              <!-- Title -->
              <div class="title text-left">
                <h2 class="p-2 font-weight-bold mb-3">{{ task.title }}</h2>
              </div>
              <div class="task-top-info d-flex justify-content-between py-2 px-2">
                <div class="d-flex flex-row">
                  <ObjectLabel v-if="task.hero" type="hero" :object="{}"/>
                  <!-- Rating -->
                  <div v-if="task.review_average > 0" class="ml-2 p-0 rating">
                    <ul class="p-0 m-0">
                      <li>
                        <i v-bind:class="{'fa' : true, 'fa-star-o' : (task.review_average < 0.5), 'fa-star' : ( 0.5 <= task.review_average )}" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i v-bind:class="{'fa' : true, 'fa-star-o' : (task.review_average < 1.5), 'fa-star' : ( 1.5 <= task.review_average )}" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i v-bind:class="{'fa' : true, 'fa-star-o' : (task.review_average < 2.5), 'fa-star' : ( 2.5 <= task.review_average )}" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i v-bind:class="{'fa' : true, 'fa-star-o' : (task.review_average < 3.5), 'fa-star' : ( 3.5 <= task.review_average )}" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i v-bind:class="{'fa' : true, 'fa-star-o' : (task.review_average < 4.5), 'fa-star' : ( 4.5 <= task.review_average )}" aria-hidden="true"></i>
                        <strong> {{ task.review_average.toFixed(2) }} </strong>
                        <p class="d-none d-sm-inline-block m-0">({{ task.review_count !== undefined ? task.review_count.toLocaleString() : 0 }} {{ $t('task_reviews') }})</p>
                      </li>
                    </ul>
                  </div>
                  <ObjectLabel v-else type="new" :object="this.task"></ObjectLabel>
                </div>
              </div>
              <Carousel :images="getImagesFromAssets(taskData)" :taskHash="task.hash" class="carousel-holder"
                        placeholder-image="https://images.anytask.com/image/upload/c_scale,h_425,w_680/c_scale,e_blackwhite,l_urbiknsbdw7r5mpntg5w,o_35,w_150/v1580733311/uerbsfwhzr3lsgstyv0h.png"/>
              <div class="pt-2" align="left">
                <small class="font-italic">{{ $t('task_maybe_translated') }}</small>
                <div class="description">
                  <h3 class="p-2 font-weight-bold mb-3">{{ $t('description') }}</h3>
                  <p class="mt-4" v-html="this.$sanitize(task.description)"/>
                </div>
                <div class="you-will-get">
                  <h3 class="p-2 font-weight-bold mb-3">{{ $t('package_explanation') }}</h3>
                  <p class="p-0 m-0" v-html="this.$sanitize(task.packages[0].description)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cols-12 col-lg-4 pb-3 pt-0 pt-sm-0 pt-md-3">
        <div class="sidebar-summary mb-3">
          <div class="card">
            <h3 class="p-2 font-weight-bold mb-3">{{ $t('summary_lower') }}</h3>
            <div class="d-flex flex-column summary-info">
              <div class="delivery d-flex justify-content-start align-items-center stat mb-2 pb-2">
                <div class="icon d-flex justify-content-center align-items-center"><i class="fa-regular fa-clock"></i></div>
                <div class="font-weight-bold mx-3 amount">{{ task.packages[0].delivery / 24 }}</div>
                <div>{{ $t('max_days_before_delivery') }}</div>
              </div>
              <div class="revisions d-flex justify-content-start align-items-center stat mb-2 pb-2">
                <div class="icon d-flex justify-content-center align-items-center"><i class="fa-solid fa-arrows-rotate"></i></div>
                <div class="font-weight-bold mx-3 amount">{{ task.packages[0].revisions }}</div>
                <div>{{ $t('revisions_included') }}</div>
              </div>
              <div class="languages d-flex justify-content-start align-items-center stat">
                <div class="icon d-flex justify-content-center align-items-center"><i class="fa-solid fa-globe"></i></div>
                <div class="font-weight-bold mx-3 amount">{{ languages.length }}</div>
                <div>{{ $t('language_header_for_task') }}</div>
              </div>
              <div>
                <div v-for="(lang, index) in languages" :key="index" class="language-available text-left">{{ lang.name_local }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar-profile" id="taskPreviewProfile">
          <!-- User Profile -->
          <UserTaskProfile class="mb-3"
                           :username="user.username"
                           :verify="user.verification_type"
                           :rating="user.review_average"
                           :reviews="user.review_count"
                           :online=" false"
                           :shortDescription="user.summary"
                           :buttons="false"
                           :image_path="user.photo_path"
                           :hash="user.hash"
                           :country="user.country_iso"
                           sellerResponse="0"
                           sellerDelivery="0"
                           sellerAcceptance="0"
                           :sellerQueue="0"
                           :disputeQueue="0"
                           :disputeAgainst="0"
                           :admin="false"
                           :task="task"
                           :loadPage="true"
                           :join="($t('month.' + (new Date(this.memberSince).getMonth() + 1))) + ' ' + (new Date(this.memberSince).getFullYear())"
                           popover_id="taskProfile"/>
        </div>
      </div>
    </div>
    <template #modal-footer="{ cancel }">
      <b-button size="md" variant="primary" @click="cancel()" class="button-width">
        {{ $t('create_task.preview.close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ObjectLabel from '../Global/Label'
import Carousel from '../Global/CardCarousel'
import helper from '../../helper'
import UserTaskProfile from '../Task/UserTaskProfile'

export default {
  name: 'TaskPreview',
  components: { ObjectLabel, Carousel, UserTaskProfile },
  props: {
    taskData: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    memberSince: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      joinedDate: '',
      task: {},
      load: false
    }
  },
  mounted () {
    let task = this.taskData
    task['user'] = this.user
    this.task = task
    this.load = true
  },
  methods: {
    getImagesFromAssets (media) {
      let images = []

      media.assets.forEach(function (asset) {
        let img = {}
        img['type'] = asset.type

        if (asset.type === 2) {
          // Movie
          img['src'] = helper.formatCloudinaryUrl(asset.path, 'movie_large')
          img['mp4'] = helper.formatCloudinaryUrl(asset.path, 'movie_mp4_large')
        } else {
          // Image
          img['src'] = helper.formatCloudinaryUrl(asset.path, 'task_large')
        }
        images.push(img)
      })
      return images
    }
  }
}
</script>

<style>
#taskPreviewProfile .profile-image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.headerText {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}

#task-preview .modal-footer {
  justify-content: center !important;
}
</style>

<style lang="scss" scoped>
.headerButton {
  font-size: 20px;
  cursor: pointer;
}

.carousel-holder {
  border: $light-border;
}

h3 {
  position: relative;
}

p.description {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.task-top-info {
  background: $task-grey;
  border-top: $light-border;
  border-left: $light-border;
  border-right: $light-border;
  font-size: 14px;

  li {
    display: inline-block;

    i {
      color: #ffbf00;
    }
  }
}

.sidebar-profile {
  overflow-x: hidden;
  z-index: 1;
}

.sidebar-summary {
  h3 {
    &:after {
      width: 0;
      height: 0;
    }
  }

  .card {
    padding: 1.25rem;

    .summary-info {
      font-size: 14px;
      color: $text-grey;

      .stat {
        border-bottom: 1px solid $task-light-grey;

        &.languages {
          border: 0;
        }

        .icon {
          width: 30px;
          height: 30px;
          background: $task-light-grey;
          border-radius: 8px;
          color: white;
          font-size: 1.25rem;
        }

        .amount {
          font-size: 1.25rem;
          color: $brand-denim;
        }
      }

      .language-available {
        margin-left: 75px;
        font-size: .75rem;
      }
    }
  }
}

@include md-up {
  .w-md-auto {
    width: auto !important;
  }
}

@include md-down {
  .mobileDisplay {
    height: auto !important;
    max-height: calc(100vh - 200px) !important;
    overflow: overlay;
  }
  .button-width {
    width: 100%;
  }
}
</style>

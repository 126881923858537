<template>
  <div class="mt-2 px-2 text-left pb-3">
    <b-alert :show="validationMessage.length > 0" variant="danger" id="errorMessage">
      <p>{{ $t('task_error_form') }}</p>
      <ul>
        <li class="errorBulletPoints" v-for="(message, index) in validationMessage" :key="index">{{ message }}</li>
      </ul>
    </b-alert>
    <div class="mb-4">
      <h3 class="text-left d-flex justify-content-between font-weight-bold">{{ $t('task_title') }} <span class="d-md-none font-weight-bold cursor-pointer" v-on:click="hintSwitchMethod('title')">&#63;</span></h3>
      <p class="d-flex align-items-center">{{ $t('create_task.title.description') }}</p>
      <div v-if="switchAdvice.title" class="alert alert-warning align-items-center d-flex small text-left">
        <i class="fa-regular fa-lightbulb fa-2x pr-3" aria-hidden="true"/>
        <p class="m-0">
          {{ $t('create_task.title.advice') }}
        </p>
      </div>
      <div>
        <input id="taskTitle" type="text" class="form-control" v-model="taskData.title" required>
      </div>
    </div>

    <div class="mb-4">
      <h3 class="text-left d-flex justify-content-between font-weight-bold">{{ $t('task_description') }} <span class="d-md-none font-weight-bold cursor-pointer" v-on:click="hintSwitchMethod('description')">&#63;</span></h3>
      <p class="d-flex align-items-center">{{ $t('create_task.description.description') }}</p>
      <div v-if="switchAdvice.description" class="alert alert-warning align-items-center d-flex small text-left">
        <i class="fa-regular fa-lightbulb fa-2x pr-3" aria-hidden="true"/>
        <div class="">
          {{ $t('create_task.description.advice.label') }}
          <ul class="dashed pl-0">
            <li>{{ $t('create_task.description.advice.advice_1') }}</li>
            <li>{{ $t('create_task.description.advice.advice_2') }}</li>
            <li>{{ $t('create_task.description.advice.advice_3') }}</li>
          </ul>
        </div>
      </div>
      <div>
        <vue-editor id="taskDescription"
                    name="textarea" v-model="taskData.description" required
                    :editorToolbar="customToolbar"/>
      </div>
    </div>

    <div class="mb-4">
      <h3 class="text-left d-flex justify-content-between font-weight-bold">{{ $t('task_package_description') }} <span class="d-md-none font-weight-bold cursor-pointer" v-on:click="hintSwitchMethod('package')">&#63;</span></h3>
      <p class="d-flex align-items-center">{{ $t('create_task.package_description.description') }}</p>
      <div v-if="switchAdvice.package" class="alert alert-warning align-items-center d-flex small text-left">
        <i class="fa-regular fa-lightbulb fa-2x pr-3" aria-hidden="true"/>
        <p class="m-0">
          {{ $t('create_task.package_description.advice') }}
        </p>
      </div>
      <div>
        <vue-editor id="taskPackage"
                    name="textarea" v-model="taskData.packages[0].description" required
                    :editorToolbar="customToolbar"/>
      </div>
    </div>

    <div class="mb-2">
      <div class="row mx-0">
        <div class="col-6">
          <h3 class="font-weight-bold text-left">{{ $t('translate') }}</h3>
        </div>
        <div class="col-6 text-right">
          <img src="@/assets/google-translate.png" style="max-width:120px;">
        </div>
      </div>
      <b-alert :show="translatingError" variant="danger">
        {{ $t('translations_task_error') }}
      </b-alert>
      <b-alert :show="translatingSuccess" variant="success">
        {{ $t('translations_task_success') }}
      </b-alert>
      <div class="border rounded row mx-0 p-3">
        <div class="col-12 col-md-4 mb-4 mb-md-0 d-flex align-items-center justify-content-center">
          <b-button v-on:click="taskTranslate" class="btn-outline-warning bg-white button-width">{{ $t('create_task.translation.button') }}</b-button>
        </div>
        <div class="col-12 col-md-8">
          <p class="mb-0">{{ $t('create_task.translation.description') }}</p>
        </div>
      </div>
    </div>

    <div class="text-right mt-5">
      <b-button v-on:click="validation" class="btn-primary button-width">{{ $t('create_task.create_button') }}</b-button>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import apiService from '../../apiService'
export default {
  name: 'CreateTaskSection1',
  components: {
    VueEditor
  },
  props: {
    taskTitle: {
      type: String,
      required: false,
      default: ''
    },
    taskDescription: {
      type: String,
      required: false,
      default: ''
    },
    packageDescription: {
      type: String,
      required: false,
      default: ''
    },
    hintSwitch: {
      type: Boolean,
      required: false,
      default: true
    },
    errorMessage: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      taskData: {
        'title': this.taskTitle,
        'description': this.taskDescription,
        'packages': [
          {
            'description': this.packageDescription
          }
        ]
      },
      validationMessage: [],
      customToolbar: [['bold', 'italic', 'underline']],
      translatingError: false,
      translatingSuccess: false,
      switchAdvice: {
        'title': true,
        'description': true,
        'package': true
      }
    }
  },
  watch: {
    'hintSwitch': function (val, oldVal) {
      if (val !== oldVal) {
        this.switchAdvice = {
          'title': val,
          'description': val,
          'package': val
        }
      }
    },
    'errorMessage': function (val, oldVal) {
      if (val !== oldVal) {
        this.validationMessage = val
        window.scrollTo(0, 0)
      }
    }
  },
  mounted () {
    this.switchAdvice = {
      'title': this.hintSwitch,
      'description': this.hintSwitch,
      'package': this.hintSwitch
    }
  },
  methods: {
    hintSwitchMethod (id) {
      this.switchAdvice[id] = !this.switchAdvice[id]
    },
    validation () {
      this.validationMessage = []
      let errorData = []

      let TaskDescriptionData = this.taskData.description
      if (TaskDescriptionData !== undefined) {
        TaskDescriptionData = this.$sanitize(TaskDescriptionData)
      }
      let TaskPackageData = this.taskData.packages[0].description
      if (TaskPackageData !== undefined) {
        TaskPackageData = this.$sanitize(TaskPackageData)
      }

      let data = {
        'title': this.taskData.title,
        'description': TaskDescriptionData,
        'packages': [
          {
            'description': TaskPackageData
          }
        ]
      }

      if (data.title === undefined || data.title === '') {
        errorData.push(this.$t('task_title'))
      }

      if (data.description === undefined || data.description === '') {
        errorData.push(this.$t('task_description'))
      }

      if (data.packages[0].description === undefined || data.packages[0].description === '') {
        errorData.push(this.$t('task_package_description'))
      }

      if (errorData.length > 0) {
        this.validationMessage = errorData
        window.scrollTo(0, 0)
      } else {
        this.$emit('saveData', data)
      }
    },
    taskTranslate () {
      this.translatingError = false
      this.translatingSuccess = false

      const data = {}
      if (this.taskData.title) {
        data['task_title'] = this.taskData.title
      }
      if (this.taskData.description) {
        data['task_description'] = this.taskData.description
      }
      if (this.taskData.packages[0].description) {
        data['package_description'] = this.taskData.packages[0].description
      }

      if (Object.keys(data).length > 0) {
        this.translating_task = true

        apiService.getTranslation(data, 'en').then(response => {
          this.translatingError = false
          this.translatingSuccess = true

          if (typeof response.data.data.task_title !== 'undefined') {
            this.taskData.title = response.data.data.task_title
          }
          if (typeof response.data.data.task_description !== 'undefined') {
            this.taskData.description = response.data.data.task_description
          }
          if (typeof response.data.data.package_description !== 'undefined') {
            this.taskData.packages[0].description = response.data.data.package_description
          }
        }).catch(() => {
          this.translatingError = true
          this.translatingSuccess = false
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
ul {
  margin: 0;
}
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "- ";
  text-indent: -5px;
}

.errorBulletPoints {
  list-style-type: disc;
}

@include md-down {
  .button-width {
    width: 100%;
  }
}
</style>

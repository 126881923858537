<template>
  <div class="mt-2 px-2 text-left pb-3">
    <b-alert :show="validationMessage.length > 0" variant="danger" id="errorMessage">
      <p>{{ $t('task_error_form') }}</p>
      <ul>
        <li class="errorBulletPoints" v-for="(message, index) in validationMessage" :key="index">{{ message }}</li>
      </ul>
    </b-alert>

    <div class="mb-4">
      <h3 class="text-left d-flex justify-content-between font-weight-bold">{{ $t('delivery') }} <span class="d-md-none font-weight-bold cursor-pointer" v-on:click="hintSwitchMethod('delivery')">&#63;</span></h3>
      <p class="d-flex align-items-center">{{ $t('create_task.delivery.description') }}</p>
      <div v-if="switchAdvice.delivery" class="alert alert-warning align-items-center d-flex small text-left">
        <i class="fa-regular fa-lightbulb fa-2x pr-3" aria-hidden="true"/>
        <p class="m-0">
          {{ $t('create_task.delivery.advice') }}
        </p>
      </div>
      <div class="px-3">
        <div class="range-wrap">
          <div class="range-value" id="deliveryRangeV"></div>
          <input class="w-100 range slider-progress" id="deliveryRange" type="range" min="1" max="30" v-model="taskData.delivery"
                 v-on:input="changeSlide('deliveryRange', 'deliveryRangeV' )"/>
        </div>
        <div class="row mx-0 mt-2">
          <div class="col-6 p-0 small">{{ $t('create_task.delivery.min') }}</div>
          <div class="col-6 p-0 text-right small">{{ $t('create_task.delivery.max') }}</div>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <h3 class="text-left d-flex justify-content-between font-weight-bold">{{ $t('revisions_heading') }} <span class="d-md-none font-weight-bold cursor-pointer" v-on:click="hintSwitchMethod('revisions')">&#63;</span></h3>
      <p class="d-flex align-items-center">{{ $t('create_task.revision.description') }}</p>
      <div v-if="switchAdvice.revisions" class="alert alert-warning align-items-center d-flex small text-left">
        <i class="fa-regular fa-lightbulb fa-2x pr-3" aria-hidden="true"/>
        <p class="m-0">
          {{ $t('create_task.revision.advice') }}
        </p>
      </div>
      <div class="px-4">
        <div class="range-wrap">
          <div class="range-value" id="revisionsRangeV"></div>
          <input class="w-100 range slider-progress" id="revisionsRange" type="range" min="1" max="10" v-model="taskData.revisions"
                 v-on:input="changeSlide('revisionsRange', 'revisionsRangeV')"/>
        </div>
        <div class="row mx-0 mt-2">
          <div class="col-6 p-0 small">{{ $t('create_task.revision.min') }}</div>
          <div class="col-6 p-0 text-right small">{{ $t('create_task.revision.max') }}</div>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <h3 class="text-left d-flex justify-content-between font-weight-bold">{{ $t('create_task.pricing.title') }} <span class="d-md-none font-weight-bold cursor-pointer" v-on:click="hintSwitchMethod('price')">&#63;</span></h3>
      <p class="d-flex align-items-center">{{ $t('create_task.pricing.description') }}</p>
      <div v-if="switchAdvice.price" class="alert alert-warning align-items-center d-flex small text-left">
        <i class="fa-regular fa-lightbulb fa-2x pr-3" aria-hidden="true"/>
        <p class="m-0">
          {{ $t('create_task.pricing.advice') }}
        </p>
      </div>
      <div class="mx-0">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="price input-group-text text-gray-dark smaller">$</span>
          </div>
          <input id="taskPrice" v-on:change="setTwoDecimalPlace" min="1" max="100" step="1" type="number" class="form-control" v-model="taskData.price" required>
        </div>
      </div>
    </div>

    <div class="text-right pt-2 pb-2 d-flex flex-md-row-reverse flex-column">
      <b-button v-on:click="validation" class="btn-primary button-width">{{ $t('create_task.create_button') }}</b-button>
      <b-button v-on:click="switchSection" variant="link" class="text-dark mr-0 mr-md-3 mt-2 mt-md-0 button-width">{{ $t('create_task.back_button') }}</b-button>
    </div>
  </div>

</template>

<script>

export default {
  name: 'CreateTaskSection2',
  props: {
    taskDelivery: {
      type: [Number, String],
      required: false,
      default: '24'
    },
    taskRevisions: {
      type: [Number, String],
      required: false,
      default: '1'
    },
    taskPrice: {
      type: [Number, String],
      required: false,
      default: '0'
    },
    hintSwitch: {
      type: Boolean,
      required: false,
      default: true
    },
    errorMessage: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      taskData: {
        'delivery': this.taskDelivery / 24,
        'revisions': this.taskRevisions,
        'price': this.taskPrice
      },
      validationMessage: [],
      switchAdvice: {
        'delivery': true,
        'revisions': true,
        'price': true
      }
    }
  },
  watch: {
    'hintSwitch': function (val, oldVal) {
      if (val !== oldVal) {
        this.switchAdvice = {
          'delivery': val,
          'revisions': val,
          'price': val
        }
      }
    },
    'errorMessage': function (val, oldVal) {
      if (val !== oldVal) {
        this.validationMessage = val
        window.scrollTo(0, 0)
      }
    }
  },
  mounted () {
    this.switchAdvice = {
      'delivery': this.hintSwitch,
      'revisions': this.hintSwitch,
      'price': this.hintSwitch
    }
    this.changeSlide('deliveryRange', 'deliveryRangeV')
    this.changeSlide('revisionsRange', 'revisionsRangeV')
  },
  methods: {
    changeSlide (id, rangeId) {
      const target = document.getElementById(id)
      const rangeV = document.getElementById(rangeId)
      target.style.setProperty('--value', target.value)
      target.style.setProperty('--min', target.min === '' ? '0' : target.min)
      target.style.setProperty('--max', target.max === '' ? '100' : target.max)

      const newValue = Number((target.value - target.min) * 100 / (target.max - target.min))
      const newPosition = 10 - (newValue * 0.2)
      const textValue = id === 'deliveryRange' ? this.$t('delivery_time_days') : this.$t('revisions_heading')
      rangeV.innerHTML = `<span class="bubble">${target.value + ' ' + textValue}</span>`
      rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`
    },
    setTwoDecimalPlace (e) {
      this.taskData.price = parseFloat(e.target.value).toFixed(2)
    },
    hintSwitchMethod (id) {
      this.switchAdvice[id] = !this.switchAdvice[id]
    },
    validation () {
      this.validationMessage = []
      let errorData = []

      if (this.taskData.price < 1 || this.taskData.price > 100) {
        errorData.push(this.$t('create_task.pricing.validation'))
      }

      if (errorData.length > 0) {
        this.validationMessage = errorData
        window.scrollTo(0, 0)
      } else {
        this.$emit('saveData', {
          'price': this.taskData.price,
          'revisions': this.taskData.revisions,
          'delivery': this.taskData.delivery * 24
        })
      }
    },
    switchSection () {
      this.$emit('switchSection', 0)
    }
  }
}
</script>

<style scoped lang="scss">

.errorBulletPoints {
  list-style-type: disc;
}

@include md-down {
  .button-width {
    width: 100%;
  }
  .hideIcon {
    display: none !important;
  }
  .border-danger{
    border-width: 2px ;
    border-style: solid;
  }
}
</style>

<style scoped>
input[type=range].range {
  height: 2.2em;
  -webkit-appearance: none;
}

input[type=range].range {
  height: 2.2em;
  -webkit-appearance: none;
}

input[type=range].range.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
}

input[type=range].range:focus {
  outline: none;
}

input[type=range].range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  background: #182934;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: calc(max(1.5em * 0.5,0px) - 2em * 0.5);
}

input[type=range].range::-webkit-slider-runnable-track {
  height: 0.5em;
  border: 1px solid #b2b2b2;
  border-radius: 0.5em;
  background: #efefef;
  box-shadow: none;
}
input[type=range].range.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#f07f13,#f07f13) 0/var(--sx) 100% no-repeat, #efefef;
}

input[type=range].range::-moz-range-thumb {
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background: #182934;
  border: none;
  box-shadow: 0 0 2px black;
}

input[type=range].range::-moz-range-track {
  height: max(calc(1em - 1px - 1px),0px);
  border: 1px solid #b2b2b2;
  border-radius: 0.5em;
  background: #efefef;
  box-shadow: none;
}

input[type=range].range.slider-progress::-moz-range-track {
  background: linear-gradient(#f07f13,#f07f13) 0/var(--sx) 100% no-repeat, #efefef;
}

input[type=range].range::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type=range].range::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type=range].range::-ms-thumb {
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background: #182934;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
}

input[type=range].range::-ms-track {
  height: 1em;
  border-radius: 0.5em;
  background: #efefef;
  border: 1px solid #b2b2b2;
  box-shadow: none;
  box-sizing: border-box;
}

input[type=range].range.slider-progress::-ms-fill-lower {
  height: max(calc(1em - 1px - 1px),0px);
  border-radius: 0.5em 0 0 0.5em;
  margin: -1px 0 -1px -1px;
  background: #f07f13;
  border: 1px solid #b2b2b2;
  border-right-width: 0;
}

input[type=range].range::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type=range].range::-ms-thumb {
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background: #182934;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
}

input[type=range].range::-ms-track {
  height: 1em;
  border-radius: 0.5em;
  background: #efefef;
  border: 1px solid #b2b2b2;
  box-shadow: none;
  box-sizing: border-box;
}

input[type=range].range:hover::-ms-track {
  background: #e5e5e5;
  border-color: #9a9a9a;
}

input[type=range].range:active::-ms-track {
  background: #f5f5f5;
  border-color: #c1c1c1;
}

input[type=range].range.slider-progress::-ms-fill-lower {
  height: max(calc(1em - 1px - 1px),0px);
  border-radius: 0.5em 0 0 0.5em;
  margin: -1px 0 -1px -1px;
  background: #f07f13;
  border: 1px solid #b2b2b2;
  border-right-width: 0;
}

.range-wrap{
  position: relative;
  top: 20px;
}
.range-value{
  position: absolute;
  top: -50%;
}
</style>

<style>
.bubble{
  white-space: nowrap;
  width: auto;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #182934;
  color: #fff;
  font-size: 12px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
  padding-left: 5px;
  padding-right: 5px;
}
.bubble:before{
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #182934;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}
</style>

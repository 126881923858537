<template>
  <div class="bg-white text-left">

    <b-alert :show="errors.length > 0" variant="danger" id="errorMessage">
      <p>{{ $t('task_error_form') }}</p>
      <ul>
        <li class="errorBulletPoints" v-for="(message, index) in errors" :key="index">{{ message }}</li>
      </ul>
    </b-alert>

    <h3 class="font-weight-bold text-left">{{ $t('create_task.assets.title') }}</h3>
    <p class="mb-0">{{ $t('create_task.assets.description_1') }}</p>
    <p>{{ $t('create_task.assets.description_2') }}</p>

    <vue-dropzone ref="assetUpload"
                  :options="dropzone.options"
                  :awss3="dropzone.awss3"
                  :useCustomSlot=true
                  @vdropzone-mounted="addAssets"
                  @vdropzone-complete="fileSuccess"
                  @vdropzone-s3-upload-error="fileError"
                  @vdropzone-s3-upload-success="fileUploaded"
                  @vdropzone-removed-file="fileRemoved"
                  id="dropzone">
      <div class="dropzone-custom-content text-muted">
        <i class="fa-solid fa-4x fa-cloud-arrow-up"></i>
        <br/>
        {{ $t('dropzone_placeholder') }}
      </div>
    </vue-dropzone>

    <draggable class="row dropzone-previews mb-5" draggable=".dropzone-container" @end="sortAsset"/>

    <div class="d-flex d-md-block mt-2 justify-content-center text-right" v-if="currentAssets.length">
      <div class="text-black-50">
        {{ $t('create_task.assets.sort') }}
        <span class="fa-stack text-dark drag-icon">
          <i class="fa-solid fa-arrows-up-down-left-right drag-icon-arrow" />
          <i class="fa-regular fa-hand-pointer stack-1x drag-icon-pointer" />
        </span>
      </div>
    </div>

    <h3 class="text-left font-weight-bold">{{ $t('task_advice_title_slug') }}</h3>
    <p>{{ $t('create_task.url.description') }}</p>
    <div class="display-in-middle">
      <div class="text-center mb-3">
        <input type="checkbox" class="form-check-input" id="form-allow-slug-url" v-model="slug.editable">
        <label class="form-check-label" for="form-allow-slug-url">{{ $t('create_task.url.label') }}</label>
      </div>
      <div class="form-group row">
        <div class="input-group col-12">
          <div class="d-md-block d-none input-group-prepend">
            <span class="input-group-text bg-gray text-gray-dark smaller">{{ getHostname }}/{{ user.username }}/</span>
          </div>
          <input :readonly="!slug.editable" pattern="/^([a-zA-Z0-9_]+)$/" type="text" class="form-control" v-model="slug.value" v-on:blur="slug.value = buildUrl(slug.value)">
        </div>
      </div>
    </div>

    <div class="text-right pt-2 pb-2 d-flex flex-md-row-reverse flex-column">
      <b-button v-on:click="validation" class="btn-primary">{{ $t('create_task.create_button') }}</b-button>
      <b-button v-on:click="switchSection" variant="link" class="text-dark mt-2 mt-md-0">{{ $t('create_task.back_button') }}</b-button>
    </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import apiService from '../../apiService'
import helper from '../../helper'
import config from '../../config'
import draggable from 'vuedraggable'

export default {
  name: 'CreateTaskSection4',
  components: {
    vueDropzone,
    draggable
  },
  props: {
    user: {
      type: Object,
      require: true
    },
    task: {
      type: Object,
      require: true
    },
    errorMessage: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  watch: {
    'currentAssets': function (val) {
      if (val.length) {
        this.fileSetup(val[val.length - 1])
      }
    },
    'slug.editable': function (val) {
      if (val === false) {
        this.slug.value = (this.task.slug === '' || this.task.slug == null) ? this.buildUrl(this.task.title) : this.task.slug
      }
    },
    'errorMessage': function (val, oldVal) {
      if (val !== oldVal) {
        this.errors = val
        window.scrollTo(0, 0)
      }
    }
  },
  data () {
    return {
      errors: [],
      currentAssets: [],
      dropzone: {
        awss3: {
          signingURL: apiService.getTokenUrl('task'),
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
          },
          params: {
            'hash': this.user.hash
          },
          sendFileToServer: false
        },
        options: {
          paramName: 'file',
          thumbnailHeight: 129,
          maxFilesize: 500,
          maxFiles: 12,
          autoProcessQueue: true,
          parallelUploads: 2,
          timeout: 0,
          parallelChunkUploads: false,
          retryChunks: true,
          retryChunksLimit: 3,
          addRemoveLinks: false,
          previewsContainer: '.dropzone-previews',
          previewTemplate: '<div class="col-6 col-md-3 mt-4 dropzone-container cursor-pointer" data-hash>' +
            '<div class="card dropzone-item dz-preview dz-file-preview">' +
            ' <img data-dz-thumbnail height="129" class="rounded w-100"/>' +
            ' <video data-dz-video controls class="d-none"></video>' +
            ' <div class="dropzone-main-image position-absolute p-1 w-100 text-center small d-none">Main image</div>' +
            ' <div class="dz-progress"><span class="dz-upload bg-primary" data-dz-uploadprogress></span></div>' +
            ' <div class="dz-icons dz-success-mark">' +
            '  <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"' +
            '       xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"><title>Check</title>' +
            '    <defs></defs>' +
            '    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">' +
            '      <path' +
            '        d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"' +
            '        id="Oval-2" stroke-opacity="0.198794158" stroke="#747474" fill-opacity="0.816519475" fill="#FFFFFF" sketch:type="MSShapeGroup"></path>' +
            '    </g>' +
            '  </svg>' +
            ' </div>' +
            ' <div class="dz-icons dz-error-mark">' +
            '   <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">' +
            '     <title>Error</title>' +
            '     <defs></defs>' +
            '     <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">' +
            '       <g id="Check-+-Oval-2" sketch:type="MSLayerGroup" stroke="#747474" stroke-opacity="0.198794158" fill="#FFFFFF" fill-opacity="0.816519475">' +
            '         <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" id="Oval-2" sketch:type="MSShapeGroup"></path>' +
            '       </g>' +
            '     </g>' +
            '   </svg>' +
            ' </div>' +
            ' <div class="dropzone-close cursor-pointer position-absolute d-none" data-dz-remove>' +
            '   <span class="fa-stack">' +
            '     <i class="fa-solid fa-square fa-stack-1x text-white"/>' +
            '     <i class="fa-solid fa-circle-xmark fa-stack-2x text-primary"/>' +
            '   </span>' +
            ' </div>' +
            '</div>' +
            '</div>',
          acceptedFiles: 'application/mpd,application/vnd.apple.mpegurl,video/3gpp2,video/3gpp,video/mp4,video/mpeg,video/ogg,video/quicktime,video/webm,video/x-msvideo,video/x-flv,video/x-matroska,video/x-ms-asf,video/x-ms-wmv,application/postscript,image/bmp,image/gif,image/jp2,image/jpeg,image/png,image/svg+xml,image/tif,image/tiff,image/vnd.ms-photo,image/vnd.adobe.photoshop,image/webp,image/x-icon',
          renameFile (file) {
            return new Date().valueOf() + '_' + file.name.replace(/[^0-9a-z._-]/gi, '')
          }
        }
      },
      slug: {
        editable: false,
        value: ''
      }
    }
  },
  mounted () {
    this.slug.value = (this.task.slug === '' || this.task.slug === null) ? this.buildUrl(this.task.title) : this.task.slug
  },
  methods: {
    addAssets () {
      this.task.assets.forEach(item => {
        let url = helper.formatCloudinaryUrl(item.path, item.type === 1 ? 'task' : 'movie_mp4')
        this.$refs['assetUpload'].manuallyAddFile({ size: 10, name: url.substring(url.lastIndexOf('/') + 1) }, url)
        this.currentAssets.push(item)
        this.fileSetup(item)
      })
    },
    sortAsset () {
      let hashes = []
      const elems = document.querySelectorAll('.dropzone-previews .dropzone-container');
      [].forEach.call(elems, function (el) {
        hashes.push(el.dataset.hash)
      })
      this.currentAssets.sort((a, b) => hashes.indexOf(a.hash) - hashes.indexOf(b.hash))
    },
    fileSetup (item) {
      let previewElement = document.querySelector('.dropzone-previews > div:nth-child(' + this.currentAssets.length + ')')
      if (previewElement === null || previewElement.dataset.hash !== '') {
        return
      }
      previewElement.dataset.hash = item.hash
      previewElement.classList.add('dz-finish')

      const extension = item.path.substring(item.path.lastIndexOf('/') + 1).split('.').pop()
      if (['m3u8', '3g2', '3gp', 'mp4', 'mpeg', 'ogv', 'qt', 'webm', 'avi', 'flv', 'mkv', 'mk3d', 'mka', 'mks', 'asf', 'wmv', 'mov'].includes(extension)) {
        previewElement.querySelector('[data-dz-thumbnail]').classList.add('d-none')
        let video = previewElement.querySelector('[data-dz-video]')
        video.src = helper.formatCloudinaryUrl(item.path, 'movie_mp4')
        video.classList.remove('d-none')
      }
    },
    fileSuccess (response) {
      response.previewElement.addEventListener('click', function (e) {
        const parent = (e.target.tagName.toLowerCase() === 'video') ? e.target.parentElement.parentElement : e.target
        const hasSelected = parent.classList.contains('selected')

        const elems = document.querySelectorAll('.dropzone-previews .selected');
        [].forEach.call(elems, function (el) {
          el.classList.remove('selected')
        })

        if (!hasSelected) {
          parent.classList.add('selected')
        }
      })
    },
    fileError (errorMessage) {
      this.errors.push(errorMessage)
      window.scrollTo(0, 0)
    },
    fileUploaded (s3Object) {
      apiService.addToGallery(s3Object, null).then((resp) => {
        this.currentAssets.push(resp.data.data)
      }).catch((error) => {
        if (error.response.data.code === 'HTTP-400') {
          this.errors.push(error.response.data.errors.children.asset.errors[0])
        } else {
          this.errors.push(error.response.data.error_message)
        }
        window.scrollTo(0, 0)
      })
    },
    fileRemoved (file) {
      const hash = file.previewElement.dataset.hash
      const index = this.currentAssets.findIndex(item => item.hash === hash)
      if (index > -1) {
        this.currentAssets.splice(index, 1)
      }
    },
    buildUrl (value) {
      let slug = ''
      slug = value.normalize('NFKD').replace(/[\u0300-\u036F]/g, '')
      slug = slug.toLowerCase()
      slug = slug.replace(/[^\w\d\s-]/g, '')
      slug = slug.replace(/\s/g, '-')
      slug = slug.replace(/-{2,}/g, '-')
      slug = slug.replace(/(^-)|(-$)/g, '')
      return slug
    },
    validation () {
      this.errors = []

      if (this.slug.value === '' || this.slug.value == null) {
        this.errors.push(this.$t('create_task.url.validation'))
      }

      if (this.currentAssets.length === 0) {
        this.errors.push(this.$t('create_task.assets.validation'))
      }

      if (this.errors.length) {
        window.scrollTo(0, 0)
        return
      }

      this.$emit('saveData', {
        'slug': this.slug.value,
        'assets': this.currentAssets.map(asset => asset.hash)
      })
    },
    switchSection () {
      this.$emit('switchSection', 2)
    }
  },
  computed: {
    getHostname () {
      return config.frontend_url
    }
  }
}
</script>

<style scoped lang="scss">
.errorBulletPoints {
  list-style-type: disc;
}

@include md-up {
  .display-in-middle {
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
}

#dropzone {
  border: 2px dashed #dedede;
  border-radius: 5px;
  background: #f5f5f5;
  overflow: auto;

  i {
    color: #bbb
  }
}
.drag-icon {
  width: 2em;
  .drag-icon-arrow {
    position: absolute;
    top: 6px;
    font-size: 12px;
    left: 1px;
  }
  .drag-icon-pointer {
    font-size: 20px;
    transform: rotate(-25deg);
  }
}
</style>
<style lang="scss">
.dropzone-previews > div:first-child > .dropzone-item {
  .dz-success-mark {
    top: 25% !important;
  }

  > .dropzone-main-image {
    display: block !important;
  }
}

.dropzone-container {

  &.dz-complete {
    .dz-progress {
      opacity: 0 !important;
      transition: opacity 0.4s ease-in;
    }
  }

  &.dz-success:not(.dz-finish) {
    .dz-success-mark {
      animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    }
  }

  &.selected {
    .dropzone-item {
      border-color: #44aae1 !important;
      background-color: #44aae1 !important;
    }

    .dropzone-close {
      display: block !important;
      right: -15px;
      top: -15px;
      pointer-events: all;
    }
  }

  .dropzone-item {
    border-width: 3px;
    border-color: #e5e5e5;
    background-color: #e5e5e5;
    pointer-events: none;

    img {
      object-fit: cover !important;
    }

    video {
      position: relative;
      pointer-events: all;
      object-fit: cover;
      height: 129px;
    }

    .dz-progress {
      opacity: 1;
      position: absolute;
      height: 16px;
      width: 80px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 8px;
      overflow: hidden;

      .dz-upload {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: width 300ms ease-in-out;
      }
    }

    .dz-icons {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 30%;
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 100%;

      svg {
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 54px;
        height: 54px;
      }
    }

    .dropzone-main-image {
      bottom: 0;
      background-color: #e5e5e5;
    }
  }
}
</style>
